html,
body,
#root {
  background-color: transparent;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Hiragino Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
}
label,
iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--s-1);
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px var(--ui-30);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 0%, 0.25);
}
::-webkit-scrollbar-corner {
  background: hsla(0, 0%, 0%, 0.25);
}

::-webkit-scrollbar-track {
  margin: 8px 0;
  border-radius: 4px;
  box-shadow: inset 0 0 6px var(--ui-30);
}

.borderbox {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

// override bootstrap

.form-control,
input {
  color: inherit;
  &:active {
    color: inherit;
  }
  &:hover {
    color: inherit;
  }
  &:visited {
    color: inherit;
  }
}
button:focus {
  outline: 0;
}
a:hover,
a:active,
a:focus,
a:visited {
  text-decoration: none;
  outline: 0;
  color: var(--ftx-primary);
}

a {
  color: var(--ftx-primary);
}

// default color
// we get these color from web-apps repo
// we are using light for default
#root {
  --ui-10: hsl(0, 0%, 96%);
  --ui-10-a70: hsl(0, 0%, 96%, 0.7);
  --ui-10-a30: hsl(0, 0%, 96%, 0.3);
  --ui-15: hsl(0, 0%, 100%);
  --ui-15-a70: hsl(0, 0%, 100%, 0.7);
  --ui-15-a30: hsl(0, 0%, 100%, 0.3);
  --ui-20: hsl(0, 0%, 98%);
  --ui-20-a70: hsl(0, 0%, 98%, 0.7);
  --ui-20-a30: hsl(0, 0%, 98%, 0.3);
  --ui-25: hsl(0, 0%, 100%);
  --ui-25-a70: hsl(0, 0%, 92%, 0.7);
  --ui-25-a30: hsl(0, 0%, 92%, 0.3);
  --ui-30: hsl(0, 0%, 88%);
  --ui-30-a70: hsl(0, 0%, 88%, 0.7);
  --ui-30-a30: hsl(0, 0%, 88%, 0.3);
  --t-1: hsl(220, 25%, 5%);
  --t-1-a70: hsl(220, 25%, 5%, 0.7);
  --t-1-a30: hsl(220, 25%, 5%, 0.3);
  --t-2: hsl(222, 6%, 33%);
  --t-2-a70: hsl(222, 6%, 33%, 0.7);
  --t-2-a30: hsl(222, 6%, 33%, 0.3);
  --t-2-a10: hsl(222, 6%, 33%, 0.1);
  --t-3: hsl(220, 4%, 48%);
  --t-3-a70: hsl(220, 4%, 48%, 0.7);
  --t-3-a30: hsl(220, 4%, 48%, 0.3);
  --t-4: hsl(225, 2%, 59%);
  --t-4-a70: hsl(225, 2%, 59%, 0.7);
  --t-4-a30: hsl(225, 2%, 59%, 0.3);
  --t-4-a15: hsl(225, 2%, 59%, 0.15);
  --t-4-a10: hsl(225, 2%, 59%, 0.1);
  --s-1: hsl(225, 2%, 59%);
  --s-1-a70: hsl(225, 2%, 59%, 0.7);
  --s-1-a30: hsl(225, 2%, 59%, 0.3);
  --s-2: hsl(220, 25%, 5%);
  --s-2-a70: hsl(220, 25%, 5%, 0.7);
  --s-2-a30: hsl(220, 25%, 5%, 0.3);


  --ftx-primary: #00A2C7;
  --ftx-primary-bg: #00A2C7;
}

#root {
  --principal: hsl(220, 100%, 55%);
  --principal-a70: hsl(220, 100%, 55%, 0.7);
  --principal-a50: hsl(220, 100%, 55%, 0.5);
  --principal-a30: hsl(220, 100%, 55%, 0.3);
  --principal-a10: hsl(220, 100%, 55%, 0.1);
  --principal-a05: hsl(220, 100%, 50%, 0.05);
  --information: hsl(197, 95%, 55%);
  --information-a70: hsl(197, 95%, 55%, 0.7);
  --information-a30: hsl(197, 95%, 55%, 0.3);
  --information-a10: hsl(197, 95%, 55%, 0.1);
  --success: hsl(149, 78%, 40%);
  --success-a70: hsl(149, 78%, 40%, 0.7);
  --success-a30: hsl(149, 78%, 40%, 0.3);
  --success-a10: hsl(149, 78%, 40%, 0.1);
  --warning: hsl(40, 100%, 50%);
  --warning-a70: hsl(40, 100%, 50%, 0.7);
  --warning-a30: hsl(40, 100%, 50%, 0.3);
  --warning-a10: hsl(40, 100%, 50%, 0.1);
  --danger: hsl(11, 91%, 58%);
  --danger-a70: hsl(11, 91%, 58%, 0.7);
  --danger-a30: hsl(11, 91%, 58%, 0.3);
  --danger-a10: hsl(11, 91%, 58%, 0.1);
  --white: hsl(0, 0%, 100%);
  --white-a70: hsl(0, 0%, 100%, 0.7);
  --white-a30: hsl(0, 0%, 100%, 0.3);
  --black: hsl(0, 0%, 0%);
  --black-a70: hsl(0, 0%, 0%, 0.7);
  --black-a30: hsl(0, 0%, 0%, 0.3);

  --icon-default: hsl(225, 1.9%, 59.2%);
}

.blue {
  --ui-10: hsl(230, 67%, 12%);
  --ui-10-a70: hsl(230, 67%, 12%, 0.7);
  --ui-10-a30: hsl(230, 67%, 12%, 0.3);
  --ui-15: hsl(228, 67%, 17%);
  --ui-15-a70: hsl(228, 67%, 17%, 0.7);
  --ui-15-a30: hsl(228, 67%, 17%, 0.3);
  --ui-20: hsl(230, 67%, 19%);
  --ui-20-a70: hsl(230, 67%, 19%, 0.7);
  --ui-20-a30: hsl(230, 67%, 19%, 0.3);
  --ui-25: hsl(229, 67%, 22%);
  --ui-25-a70: hsl(229, 67%, 22%, 0.7);
  --ui-25-a30: hsl(229, 67%, 22%, 0.3);
  --ui-30: hsl(230, 67%, 29%);
  --ui-30-a70: hsl(230, 67%, 29%, 0.7);
  --ui-30-a30: hsl(230, 67%, 29%, 0.3);
  --t-1: hsl(0, 0%, 100%);
  --t-1-a70: hsl(0, 0%, 100%, 0.7);
  --t-1-a30: hsl(0, 0%, 100%, 0.3);
  --t-2: hsl(224, 30%, 85%);
  --t-2-a70: hsl(224, 30%, 85%, 0.7);
  --t-2-a30: hsl(224, 30%, 85%, 0.3);
  --t-2-a10: hsl(224, 30%, 85%, 0.1);
  --t-3: hsl(226, 30%, 65%);
  --t-3-a70: hsl(226, 30%, 65%, 0.7);
  --t-3-a30: hsl(226, 30%, 65%, 0.3);
  --t-4: hsl(225, 30%, 45%);
  --t-4-a70: hsl(225, 30%, 45%, 0.7);
  --t-4-a30: hsl(225, 30%, 45%, 0.3);
  --t-4-a15: hsl(225, 30%, 45%, 0.15);
  --t-4-a10: hsl(225, 30%, 45%, 0.1);
  --s-1: hsl(225, 30%, 45%);
  --s-1-a70: hsl(225, 30%, 45%, 0.7);
  --s-1-a30: hsl(225, 30%, 45%, 0.3);
  --s-2: hsl(0, 0%, 100%);
  --s-2-a70: hsl(0, 0%, 100%, 0.7);
  --s-2-a30: hsl(0, 0%, 100%, 0.3);

  --icon-default: hsl(225.2, 30.1%, 44.9%);
}

.light {
  --ui-10: hsl(0, 0%, 96%);
  --ui-10-a70: hsl(0, 0%, 96%, 0.7);
  --ui-10-a30: hsl(0, 0%, 96%, 0.3);
  --ui-15: hsl(0, 0%, 100%);
  --ui-15-a70: hsl(0, 0%, 100%, 0.7);
  --ui-15-a30: hsl(0, 0%, 100%, 0.3);
  --ui-20: hsl(0, 0%, 98%);
  --ui-20-a70: hsl(0, 0%, 98%, 0.7);
  --ui-20-a30: hsl(0, 0%, 98%, 0.3);
  --ui-25: hsl(0, 0%, 100%);
  --ui-25-a70: hsl(0, 0%, 92%, 0.7);
  --ui-25-a30: hsl(0, 0%, 92%, 0.3);
  --ui-30: hsl(0, 0%, 88%);
  --ui-30-a70: hsl(0, 0%, 88%, 0.7);
  --ui-30-a30: hsl(0, 0%, 88%, 0.3);
  --t-1: hsl(220, 25%, 5%);
  --t-1-a70: hsl(220, 25%, 5%, 0.7);
  --t-1-a30: hsl(220, 25%, 5%, 0.3);
  --t-2: hsl(222, 6%, 33%);
  --t-2-a70: hsl(222, 6%, 33%, 0.7);
  --t-2-a30: hsl(222, 6%, 33%, 0.3);
  --t-2-a10: hsl(222, 6%, 33%, 0.1);
  --t-3: hsl(220, 4%, 48%);
  --t-3-a70: hsl(220, 4%, 48%, 0.7);
  --t-3-a30: hsl(220, 4%, 48%, 0.3);
  --t-4: hsl(225, 2%, 59%);
  --t-4-a70: hsl(225, 2%, 59%, 0.7);
  --t-4-a30: hsl(225, 2%, 59%, 0.3);
  --t-4-a15: hsl(225, 2%, 59%, 0.15);
  --t-4-a10: hsl(225, 2%, 59%, 0.1);
  --s-1: hsl(225, 2%, 59%);
  --s-1-a70: hsl(225, 2%, 59%, 0.7);
  --s-1-a30: hsl(225, 2%, 59%, 0.3);
  --s-2: hsl(220, 25%, 5%);
  --s-2-a70: hsl(220, 25%, 5%, 0.7);
  --s-2-a30: hsl(220, 25%, 5%, 0.3);

  --icon-default: hsl(225, 1.9%, 59.2%);
}

.dark {
  --ui-10: hsl(221, 35%, 9%);
  --ui-10-a70: hsl(221, 35%, 9%, 0.7);
  --ui-10-a30: hsl(221, 35%, 9%, 0.3);
  --ui-15: hsl(220, 34%, 12%);
  --ui-15-a70: hsl(220, 34%, 12%, 0.7);
  --ui-15-a30: hsl(220, 34%, 12%, 0.3);
  --ui-20: hsl(220, 35%, 13%);
  --ui-20-a70: hsl(220, 35%, 13%, 0.7);
  --ui-20-a30: hsl(220, 35%, 13%, 0.3);
  --ui-25: hsl(220, 36%, 15%);
  --ui-25-a70: hsl(220, 36%, 15%, 0.7);
  --ui-25-a30: hsl(220, 36%, 15%, 0.3);
  --ui-30: hsl(219, 35%, 21%);
  --ui-30-a70: hsl(219, 35%, 21%, 0.7);
  --ui-30-a30: hsl(219, 35%, 21%, 0.3);
  --t-1: hsl(0, 0%, 100%);
  --t-1-a70: hsl(0, 0%, 100%, 0.7);
  --t-1-a30: hsl(0, 0%, 100%, 0.3);
  --t-2: hsl(220, 20%, 82%);
  --t-2-a70: hsl(220, 20%, 82%, 0.7);
  --t-2-a30: hsl(220, 20%, 82%, 0.3);
  --t-2-a10: hsl(220, 20%, 82%, 0.1);
  --t-3: hsl(219, 20%, 62%);
  --t-3-a70: hsl(219, 20%, 62%, 0.7);
  --t-3-a30: hsl(219, 20%, 62%, 0.3);
  --t-4: hsl(220, 21%, 42%);
  --t-4-a70: hsl(220, 21%, 42%, 0.7);
  --t-4-a30: hsl(220, 21%, 42%, 0.3);
  --t-4-a15: hsl(220, 21%, 42%, 0.15);
  --t-4-a10: hsl(220, 21%, 42%, 0.1);
  --s-1: hsl(220, 21%, 42%);
  --s-1-a70: hsl(220, 21%, 42%, 0.7);
  --s-1-a30: hsl(220, 21%, 42%, 0.3);
  --s-2: hsl(0, 0%, 100%);
  --s-2-a70: hsl(0, 0%, 100%, 0.7);
  --s-2-a30: hsl(0, 0%, 100%, 0.3);

  --icon-default: hsl(220, 20.9%, 42.2%);
}
