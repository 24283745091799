.button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  // border-radius: 0.5rem;
  border-radius: 28px;
  color: #fff;
  background: var(--ftx-primary);
  border: none;
  width: 100%;
  position: relative;
  text-transform: uppercase;

  // lighten any color background on hover/active
  &:hover,
  &:active {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0.3rem;
      background: rgba(255, 255, 255, 0.15);
    }
  }

  &:disabled,
  .disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  .spinner-border {
    position: relative;
    top: 0px;
  }
}
.spinner {
  font-size: 8px;
  width: 20px;
  height: 20px;
}
