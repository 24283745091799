.kyc-intro {
  .maybe-collapse {
    transition: max-height 0.3s ease;
    overflow: hidden;
  }
  .collapsed {
    max-height: 0;
  }
  .infoBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.2em 0em 1em 0em;
  }
  .poaBlock button {
    margin-top: 3em;
  }
  .imageBlock,
  .textBlock {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    white-space: break-spaces;
  }
  .imageBlock {
    flex: 0 18%;
  }
  .textBlock {
    flex: 0 82%;
  }
  .textTitle {
    display: block;
    font-weight: bold;
  }
  .textDescription {
    display: block;
  }
  .infoBlock:nth-of-type(2n + 1) {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .heading {
    margin: 1em 0;
  }
  /* Compiles to: */
  @media (min-width: 0) and (max-width: 700px) {
    .imageBlock {
      svg {
        width: 43px;
      }
    }
    .hide-mobile {
      display: none;
    }
  }

  .sumsub-option {
    padding: 32px 24px;
    border-radius: 20px;
    // border: 1px solid grey;
    margin-top: 32px;
    // box-shadow: 000000 8% opacity, blur 24, đổ xuống dưới 24px
    box-shadow: 0 24px 24px rgba(0, 0, 0, 0.08);

    .infoBlock {
      margin-top: 0;
    }

    .next-btn {
      margin-top: 20px;
      margin-bottom: 10px;
      background-color: #39ad92;
    }

    .imageBlock {
      color: rgb(113, 181, 109);
      display: inline-flex;
      justify-content: center;
    }
    .textTitle {
      color: rgb(113, 181, 109);
    }
    .textDescription {
      font-size: 13px;
      color: rgb(79, 82, 89);
    }
  }

  .singpass-invalid {
    margin-top: 16px;
    background: rgba(245, 85, 50, 0.1);
    border-radius: 4px;
    align-items: center;
    padding: 11px 12px;

    a {
      display: block;
      text-decoration: underline;
    }
  }
}
.kyc-block-container {
  // min-height: 19.4em;

  .kycTextDescription {
    margin-bottom: 0;
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid rgb(212, 218, 224);
    max-width: 32rem;
    margin: 0 auto;
    padding: 30px 0 0;

    .footer-partnership {
      display: flex;
      .footer-partnership-icon {
        margin-right: 16px;
        background: #000000;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      span {
        flex: 1;
        font-size: 13px;
      }
    }
  }
}
.kyc-status {
  margin-right: 20px;
  font-size: 23px;
  svg {
    color: #972121;
    margin-right: 7px;
  }
  &.approved {
    svg {
      color: #2d7e6b;
    }
  }
}

@media (max-width: 600px) {
  .kycTitleDescription {
    font-size: 1.9rem;
  }
}