.checkbox {
  padding: 0px;
  margin-top: 20px;

  margin-bottom: 20px;

  align-items: center;

  .form-check {
    padding-left: 32px;

    label {
      cursor: pointer;
    }
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    left: 0;
    border: 1px solid #D4DAE0;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(35deg);
    }
  }

  .form-check-input {
    display: none;

    /* When the checkbox is checked, add a blue background */
    &:checked ~ .checkmark {
      background-color: var(--ftx-primary);
      border: none;
    }

    /* Show the checkmark when checked */
    &:checked ~ .checkmark:after {
      display: block;
    }
  }
}
